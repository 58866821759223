export default function (component) {
  return {
    email: {
      name: 'user.email',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emailEmpty')
        },
        {
          fn: component.validateEmail,
          msg: component.$t('errors.emailInvalid')
        }
      ]
    },
    password: {
      name: 'user.password',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.passwordEmpty')
        },
        {
          fn: component.validatePassword,
          msg: component.$t('errors.passwordLength')
        }
      ]
    },
    repeatPassword: {
      name: 'user.repeatPassword',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emptyAddress')
        },
        {
          fn: component.validateRepeatPassword,
          msg: component.$t('errors.passwordsNotMatching'),
          param: 'password'
        }
      ]
    },
    amountGiven: {
      name: 'amountGiven',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emptyAmount')
        },
        {
          fn: component.validateNumber,
          msg: component.$t('errors.nanAmount')
        },
        {
          fn: component.validatePositiveNumber,
          msg: component.$t('errors.negativeAmount')
        },
        {
          fn: component.validateNumberLargerThenZero,
          msg: component.$t('errors.zeroOrLessAmount')
        }
      ]
    },
    addressGiven: {
      name: 'addressGiven',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emptyAddress')
        }
      ]
    },
    addressIBAN: {
      name: 'addressIBAN',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emptyIBAN')
        }
      ]
    },
    beneficiary: {
      name: 'beneficiary',
      validations: [
        {
          fn: component.fieldEmpty,
          msg: component.$t('errors.emptyBeneficiary')
        }
      ]
    },
    paymentMethod: {
      name: 'paymentMethod',
      validations: [{
        fn: component.fieldEmpty,
        msg: component.$t('errors.emptyPaymentMethod')
      }]
    },
    receiverAccountId: {
      name: 'receiverAccountId',
      validations: [{
        fn: component.fieldEmpty,
        msg: component.$t('errors.emptyReceiverAccountId')
      }]
    }
  }
}
