export default {
  data: () => ({ emailRegex: /^.+@.+\..+$/ }),
  methods: {
    fieldEmpty (field, msg) {
      if (field === '') {
        return msg
      }
      return null
    },
    validateNumber (field, msg) {
      if (Number.isNaN(Number(field))) {
        return msg
      }
      return null
    },
    validatePositiveNumber (field, msg) {
      if (Number(field) < 0) {
        return msg
      }
      return null
    },
    validateNumberLargerThenZero (field, msg) {
      if (field !== null && Number(field) <= 0) {
        return msg
      }
      return null
    },
    validateEmail (email, msg) {
      if (email && email.length > 0 && !this.emailRegex.test(email)) {
        return msg
      }
      return null
    },
    validatePassword (password, msg) {
      if (password && password.length < 6) {
        return msg
      }
      return null
    },
    validateRepeatPassword (repeatPassword, msg, password) {
      if (repeatPassword && repeatPassword !== password) {
        return msg
      }
      return null
    }
  }
}
