<template>
  <div class="mobile-width centered p-5">
    <div
      class="px-4 py-6 mail-send-success"
      v-html="$t('dialogs.messages.sendVerificationMailSuccess')"
      v-if="sendEmail"
    >
    </div>
    <form class="px-4 py-6" v-else>
      <b-message v-if="error" type="is-danger">
        {{ error }}
      </b-message>
      <b-field
        class="mb-4"
        :type="{
          'is-danger': fieldError.email,
          'is-success': emailRegex.test(user.email)
        }"
        :message="fieldError.email"
      >
        <b-input
          v-model="user.email"
          type="email"
          :placeholder="$t('fields.email')"
        ></b-input>
      </b-field>
      <div class="is-flex mb-5">
        <b-checkbox
          class="has-text-light"
          v-model="user.termsAccepted"
        ></b-checkbox>
        <span class="has-text-light is-size-7">
          <a href="/terms"
            target="_blank"
            class="has-text-light is-size-7 lbl-accept-terms"
            v-html="$t('labels.acceptTerms')"
          ></a>
          {{ $t('labels.and') }}
          <a href="/privacy"
            target="_blank"
            class="has-text-light is-size-7 lbl-accept-terms"
            v-html="$t('labels.acceptPrivacyPolicy')"
          ></a>
        </span>
      </div>
      <div class="is-flex mb-5">
        <b-checkbox
          class="has-text-light"
          v-model="user.privacyPolicyAccepted"
        ></b-checkbox>
        <a href="/biometrics"
          target="_blank"
          class="has-text-light is-size-7"
          v-html="$t('labels.acceptBiometricsPolicy')"
        ></a>
      </div>
      <b-button
        class="btn-create-account"
        native-type="submit"
        type="is-primary"
        size="is-medium"
        :loading="loading"
        expanded
        @click.prevent="handleSendVerification"
        :disabled="invalidForm"
      >
        <span v-html="$t('buttons.createAccount')"></span>
      </b-button>
    </form>
    <nav class="has-text-centered is-size-7">
      <div>
        <router-link to="/account/login" class="has-text-light" href="#">
          {{ $t("labels.haveAccount") }}
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import validationMixin from '@/mixins/validation-mixin.js'
import validation from '@/services/validation'
import validationsInit from '@/services/validations_config'

export default {
  name: 'Register',
  mixins: [validationMixin],
  data () {
    let isBrowser = true
    if (global.device && global.device.platform) {
      isBrowser = global.device.platform === 'browser'
    }
    return {
      user: {
        email: null,
        termsAccepted: null,
        privacyPolicyAccepted: null
      },
      loading: false,
      error: null,
      sendEmail: false,
      isBrowser,
      errors: []
    }
  },
  computed: {
    ...validation
  },
  methods: {
    ...mapActions({
      sendVerificationEmail: 'auth/sendVerificationEmail'
    }),
    async handleSendVerification () {
      if (this.invalidForm) {
        return
      }
      this.loading = true
      try {
        const resReg = await this.sendVerificationEmail(this.user)
        if (!resReg) {
          this.error = this.$t('errors.noConnectionServerRegistration')
          this.loading = false
          return
        }
        this.loading = false
        this.sendEmail = true
      } catch (error) {
        this.loading = false
        if (error && error?.response?.status === 409) {
          this.error = this.$t('errors.emailAlreadyRegistered')
        } else {
          this.error = this.$t('errors.somethingWrong')
        }
      }
    }
  },
  mounted () {
    const validations = validationsInit(this)
    this.errors = [
      validations.email,
      {
        name: 'user.termsAccepted',
        validations: []
      },
      {
        name: 'user.privacyPolicyAccepted',
        validations: []
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
.mail-send-success {
  color: #fff;
  text-align: center;
}
.lbl-accept-terms {
  margin-top: 0.9rem;
}

.tooltip {
  margin-top: 0.6rem;
}
</style>
