export default {
  fieldError () {
    const result = {}
    this.errors.forEach(e => {
      const path = e.name.split('.')
      const name = path.shift()
      const value = path.length
        ? path.reduce((o, key) => o[key], this[name])
        : this[name]
      const field = path.length ? path.pop() : name
      result[field] = e.validations.reduce(
        (acc, o) => acc || o.fn(value, o.msg, this[o.param]),
        null
      )
    })

    return result
  },
  invalidForm () {
    let result =
      this.loading || Object.values(this.fieldError).some(e => e !== null)
    this.errors.forEach(e => {
      const path = e.name.split('.')
      const name = path.shift()
      const value = path.reduce((o, key) => o[key], this[name])
      result ||= !value || (typeof value !== 'boolean' && !value.length)
    })

    return result
  }
}
